html, body, #root {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  background: linear-gradient(to right top, #191840 ,#2E327C) fixed !important;
  
}

#root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}