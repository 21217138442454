#tsparticles {
  position: relative;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0;
  margin: 0;
  z-index: 0;
  /* if you use -1 you have to set to `"window"` the interactivity.detectsOn property */
}
.particleImageBox {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.particleImageBox > img {
  overflow: hidden;
  height: 100%;
  width: 100%;
}
.particleImageBox > .particles {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0px;
  left: 0px;
}
